export const SITE_SETTINGS = {
    isCMS: true,
    isDatabaseEnable: true,
    isTelegramEnabled: false,
    isRentalFixedPrice: true,
    isSMSEnabled: false,
    OTP: false,
    siteName: 'One Dropstaxi',
    phoneNo: '9042824008',
    whatsapp: '+919042824008',
    websiteAddress: 'www.onedropstaxi.in',
    smsKey: '',
    enquiryEmail: 'onedropstaxi@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-oceanic-glazing-309707.cloudfunctions.net/sms/api/v1/sendsms',
    tariffAPI: 'https://cdn.contentful.com/spaces/0p8akdvur1r4/environments/master/entries?access_token=cDrHldc07pZMof4-sEukQEHhoiojDCQvi4hdpuK5mYs',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Confirmation Email From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '',
    tGroup: '',
    telegramAPI: '',
    contentAPI: 'https://cdn.contentful.com/spaces/0p8akdvur1r4/environments/master/entries?access_token=cDrHldc07pZMof4-sEukQEHhoiojDCQvi4hdpuK5mYs',
    logoURL:'https://www.onedropstaxi.com/assets/logo.png',
    OTPApi:''
}