export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCSsk_UZuOmFp3iPt14aMU_jSuKTnmwlDE",
  authDomain: "onedropstaxi.firebaseapp.com",
  databaseURL: "https://onedropstaxi-default-rtdb.firebaseio.com",
  projectId: "onedropstaxi",
  storageBucket: "onedropstaxi.appspot.com",
  messagingSenderId: "360143641592",
  appId: "1:360143641592:web:7e8d6ce1987ddbb792540d"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
