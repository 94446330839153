export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCSsk_UZuOmFp3iPt14aMU_jSuKTnmwlDE",
  authDomain: "onedropstaxi.firebaseapp.com",
  databaseURL: "https://onedropstaxi-default-rtdb.firebaseio.com",
  projectId: "onedropstaxi",
  storageBucket: "onedropstaxi.appspot.com",
  messagingSenderId: "360143641592",
  appId: "1:360143641592:web:7e8d6ce1987ddbb792540d"
  }
};
